/* outfit-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 300;
  src: url('../public/outfit-v11-latin-300.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* outfit-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 700;
  src: url('../public/outfit-v11-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

* {
  box-sizing: border-box;
  font-family: 'Outfit', sans-serif;
  font-weight: 300;
  cursor:none;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: #0038FF;

}
#cursor {
  --size: 20px;
  position: fixed;
  top: 0;
  left: 0;
  height: var(--size);
  width: var(--size);
  border: 1px solid #ffffff;
  border-radius: 50%;
  transform: translate3d(
    calc(100vw * var(--cursor-x)), 
    calc(100vh * var(--cursor-y)), 
    0);
  pointer-events: none;
  margin-left: -10px;
  margin-top: -10px;
  z-index: 100;
  /*mix-blend-mode: difference;*/
  /*background-image: linear-gradient(45deg, rgba(0, 34, 252, 0.5), rgba(0, 176, 252, 0.5));*/
  background-color: rgba(0, 34, 252, 0.3);
  backdrop-filter: blur(5px);
  transition: height 0.2s ease, width 0.2s ease, margin-top 0.2s ease, margin-left 0.2s ease, background-color 0.2s ease;
}
/* hide cursor on touch devices */
@media (hover: none) {
  #cursor {
    display: none;
  }
}
.touchDevice #cursor {
  display: none;
}
.touchDevice * {
  cursor: auto;
}

.link-hovered #cursor {
  --size: 30px;
  margin-left: -15px;
  margin-top: -15px;
}

a {
  color: black;
}

input[type='range'] {
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: black;
  border-radius: 5px;
  width: 100%;
  height: 2px;
  outline: 0;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  background-color: #000;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

input[type='range']::-webkit-slider-thumb:active {
  transform: scale(1);
}

#logo {
  max-width: 100%;
  width: 42vh;
  margin-top: 3vh;
}
@media screen and (max-height: 850px) {
  #logo {
    width: 50vh;
  }
}
@media screen and (max-width: 500px) {
  #logo {
    margin-top: 0;
  }
}

p {
  color: #fff;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 3.04px;
}

p a {
  color: #fff;
  font-weight: 700;
}
.overlay {
  text-align: center;
}

.overlay p {
  font-size: 1.5vh;
  line-height: 180%;
  letter-spacing: 0.3vh;
}
@media screen and (max-height: 850px) {
  .overlay p {
    font-size: 2.3vh;
    line-height: 150%;
    letter-spacing: 0.1vh;
  }
}
@media screen and (max-width: 500px) {
  .overlay p {
    font-size: 3.3vw;
    line-height: 150%;
    letter-spacing: 0.6vw;
  }
}
.center-shadow {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1px;
  height: 1px;
  background-color: #0038FF;
  opacity: 0.8;
  box-shadow: 0px 0px 10vh 22vh #0038ff;
}

.imprint {
  font-size: 0.8rem;
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.76px;
}


.imprint-overlay {
  position: absolute;
  bottom: 10vw;
  left: 10vw;
  right: 10vw;
  top: 10vw;
  border-radius: 10px;
  padding: 40px;
  background: rgba(0, 34, 252, 0.5);
  background-image: linear-gradient(45deg, rgba(0, 34, 252, 0.5), rgba(0, 176, 252, 0.5));
  border: 1px solid #2a5df5;
  color: #fff;
  font-size: 1.2rem;
  z-index: 10;
  backdrop-filter: blur(10px);
  opacity: 0;
  visibility: hidden;
  transition: opacity .5s ease-in-out, transform .5s ease-in-out;
  transform: translateY(10px);
}
@media screen and (max-width: 768px) {
  .imprint-overlay {
    bottom: 100px;
    padding: 10px;
    font-size: 1rem;
  }
}


.imprint-active .imprint-overlay {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
}

.imprint-txt-imprint {
  display: block;
}
.imprint-txt-close {
  display: none;
}
.imprint-active .imprint-txt-imprint {
  display: none;
}
.imprint-active .imprint-txt-close {
  display: block;
}